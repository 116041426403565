import ErrorPage from "pages/ErrorPage";
import Dashboard from "pages/Dashboard/dashboard.container";
import LoginForm from "pages/Login/login.container";
import DesignerLogin from "pages/designerLogin";
import { IRoute } from "utils/interfaces/route";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Layout from "components/Layout";
import { useCallback, useEffect } from "react";
import {
  checkValidToken,
  isTokenExpired,
  setCookieWithExpiry,
} from "utils/helper";
import ResetPassword from "pages/ResetPassword/reset-password.container";
import ForgotPassword from "pages/ForgotPassword/forgot-password.container";
import Location from "pages/Location/location.container";
import Roles from "pages/Roles/role.container";
import AddEditRole from "pages/Roles/AddEditRole/add-edit-role.container";
import Terms from "pages/Terms/term.container";
import Profile from "pages/Profile/profile.container";
import Courses from "pages/Courses/course.container";
import AddEditCourse from "pages/Courses/AddEditCourse/add-edit-course.container";
import Educators from "pages/Educators/educator.container";
import AddEditEducator from "pages/Educators/AddEditEducator/add-edit-educator.container";
import CoursesDelivered from "pages/Educators/EducatorDetails/CoursesDelivered";
import CoursesAttendedAsStudent from "pages/Educators/EducatorDetails/CoursesAttendedAsStudent";
import ScheduledOfferings from "pages/SCO/sco.container";
import ScheduledOfferingsDetails from "pages/SCO/SCODetails/sco-details.container";
import EducatorsHome from "pages/EducatorsHome/educator-home.container";
import EducatorsHomeDetails from "pages/EducatorsHome/EducatorsHomeDetails/educator-home-details.container";
import DesignerDashboard from "pages/designerDashboard";
import DesignerRoles from "pages/designerRoles";
import DesignerForgotPassword from "pages/designerForgetPassword";
import DesignerResetPassword from "pages/designerResetPassword";
import DesignerLocation from "pages/designerLocation";
import DesignerTerms from "pages/designerTerms";
import DesignerEducators from "pages/designerEducators";
import DesignerCourses from "pages/designerCourses";
import DesignerReportsStudent from "pages/designerReportsStudent";
import DesignerReportStaff from "pages/designerReportsStaff";
import DesignerReportEducator from "pages/designerReportsEducator";
import { connect, useDispatch } from "react-redux";
import { refreshTokenRequest } from "store/auth/actions";
import { RefreshTokenSuccessPayload } from "store/auth/types";
import {
  checkIsAuthenticated,
  getAuthSelector,
  getUserSelector,
} from "store/auth/selector";
import { AuthState } from "store/rootReducer";
import { ILoginResponse } from "utils/interfaces/login";
import DesignerEducatorsHome from "pages/designerEducatorsHome";
import DesignerProfile from "pages/designerProfile";
import DesignerSco from "pages/designerSCO";
import { AppRoutings } from "utils/enums/app-routings";
import DesignerStudentTemp from "pages/designerStudentTemp";
import DesignerStudent from "pages/designerStudent";
import DesignerStudentEnrol from "pages/designerStudentEnroll";
import DesignerStudentLogin from "pages/DesignerStudentLogin";
import DesignerStudentDashboard from "pages/designerStudentDashboard";
import StudentEnrolmentForm from "pages/StudentEnrolmentForm/student-enrolment-form.container";
import StudentEnrolments from "pages/StudentEnrolments/student-enrolments.container";
import Student from "pages/Students/student.container";
import StudentDetails from "pages/Students/StudentDetails/student-details.container";
import Users from "pages/Users/user.container";
import EnrollmentSummary from "pages/Students/EnrollmentSummary/enrollmentsummary.container";
import ApproveRejectEnrolments from "pages/StudentEnrolments/ApproveRejectEnrolments/approve-reject-enrolment.container";
import Documents from "pages/Documents/document.container";
import StudentAttendance from "pages/Students/StudentAttendance/studentAttendane.container";
import StudentProfile from "pages/StudentProfile/student-profile.container";
import StudentDashboard from "pages/StudentDashboard/student-dashboard.container";
import StudentDashboardCourseDetail from "pages/StudentDashboard/CourseOfferingDetails/courseoffering-details.container";
import MyEnrolments from "pages/MyEnrolments/my-enrolment.container";
import Handbook from "pages/Handbook";
import CourseGuide from "pages/CourseGuide";
import TermTimetable from "pages/TermTimetable";
import EducatorHandbook from "pages/EducatorHandbook";
import Reports from "pages/Report/report.container";
import StaffReport from "pages/StaffReport/staff-report.container";
import CourseReport from "pages/CourseReport/course-report.container";
import SLPReport from "pages/SLPReport";
import EducatorDocuments from "pages/EducatorDocuments/educator-documents.container";
import StudentManual from "pages/StudentManual";
import EducatorManual from "pages/EducatorManual";

export type RouteProps = {
  user: ILoginResponse;
  token: string;
  isAuthenticated: boolean;
};

const designerRoutes: IRoute[] = [
  {
    id: 1,
    component: DesignerLogin,
    path: AppRoutings.DesignerLogin,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 2,
    component: DesignerDashboard,
    path: AppRoutings.DesignerDashboard,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 3,
    component: DesignerRoles,
    path: AppRoutings.DesignerRoles,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 4,
    component: DesignerForgotPassword,
    path: AppRoutings.DesignerForgotPassword,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 5,
    component: DesignerResetPassword,
    path: AppRoutings.DesignerResetPassword,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 6,
    component: DesignerLocation,
    path: AppRoutings.DesignerLocation,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 7,
    component: DesignerTerms,
    path: AppRoutings.DesignerTerms,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 8,
    component: DesignerEducators,
    path: AppRoutings.DesignerEducators,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 10,
    component: DesignerCourses,
    path: AppRoutings.DesignerCourses,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 11,
    component: DesignerEducatorsHome,
    path: AppRoutings.DesignerEducatorsHome,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 12,
    component: DesignerProfile,
    path: AppRoutings.DesignerProfile,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 13,
    component: DesignerSco,
    path: AppRoutings.DesignerSco,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 14,
    component: DesignerStudentTemp,
    path: AppRoutings.DesignerStudentTemp,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 15,
    component: DesignerStudent,
    path: AppRoutings.DesignerStudent,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 16,
    component: DesignerStudentEnrol,
    path: AppRoutings.DesignerStudentEnrol,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 17,
    component: DesignerStudentLogin,
    path: AppRoutings.DesignerStudentLogin,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 18,
    component: DesignerStudentDashboard,
    path: AppRoutings.DesignerStudentDashboard,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 19,
    component: DesignerReportsStudent,
    path: AppRoutings.DesignerReportsStudent,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 20,
    component: DesignerReportStaff,
    path: AppRoutings.DesignerReportStaff,
    exact: true,
    isProtectedRoute: false,
  },
  {
    id: 21,
    component: DesignerReportEducator,
    path: AppRoutings.DesignerReportEducator,
    exact: true,
    isProtectedRoute: false,
  },
];

const Routes = ({ user, token, isAuthenticated }: RouteProps) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const onRefreshTokenSuccess = async (
    response: RefreshTokenSuccessPayload
  ) => {
    setCookieWithExpiry("auth_token", response?.token);
  };

  const refreshTokenCall = useCallback(() => {
    const payload = {
      values: {
        token,
      },
      callback: onRefreshTokenSuccess,
    };

    dispatch(refreshTokenRequest(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    checkValidToken(history, user, token, refreshTokenCall);
  }, [history, user, token, refreshTokenCall]);

  const routes: IRoute[] = [
    ...designerRoutes,
    {
      id: 1,
      component: Dashboard,
      path: AppRoutings.Dashboard,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 2,
      component: LoginForm,
      path: AppRoutings.LogIn,
      exact: true,
      isProtectedRoute: false,
    },
    {
      id: 3,
      component: ErrorPage,
      path: AppRoutings.Error,
      exact: true,
      isProtectedRoute: false,
    },
    {
      id: 4,
      component: ResetPassword,
      path: AppRoutings.ResetPassword,
      exact: false,
      isProtectedRoute: false,
    },
    {
      id: 5,
      component: ForgotPassword,
      path: AppRoutings.ForgotPassword,
      exact: true,
      isProtectedRoute: false,
    },
    {
      id: 6,
      component: Roles,
      path: AppRoutings.Roles,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 7,
      component: Location,
      path: AppRoutings.Locations,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 8,
      component: AddEditRole,
      path: AppRoutings.AddRole,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 9,
      component: AddEditRole,
      path: AppRoutings.EditRole,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 10,
      component: Terms,
      path: AppRoutings.Terms,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 11,
      component: Profile,
      path: AppRoutings.Profile,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 12,
      component: Courses,
      path: AppRoutings.Courses,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 13,
      component: AddEditCourse,
      path: AppRoutings.AddCourse,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 14,
      component: AddEditCourse,
      path: AppRoutings.EditCourse,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 15,
      component: Educators,
      path: AppRoutings.Educators,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 16,
      component: AddEditEducator,
      path: AppRoutings.AddEducator,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 17,
      component: AddEditEducator,
      path: AppRoutings.EditEducator,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 18,
      component: ScheduledOfferings,
      path: AppRoutings.ScheduledOfferings,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 19,
      component: ScheduledOfferingsDetails,
      path: AppRoutings.ScheduledOfferingsDetails,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 20,
      component: CoursesDelivered,
      path: AppRoutings.CoursesDelivered,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 21,
      component: CoursesAttendedAsStudent,
      path: AppRoutings.CoursesAttendedAsStudent,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 22,
      component: EducatorsHome,
      path: AppRoutings.EducatorsHome,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 23,
      component: EducatorsHomeDetails,
      path: AppRoutings.EducatorsHomeDetails,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 24,
      component: StudentEnrolmentForm,
      path: AppRoutings.StudentEnrolmentForm,
      exact: true,
      isProtectedRoute: false,
    },
    {
      id: 25,
      component: StudentEnrolments,
      path: AppRoutings.StudentEnrolments,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 26,
      component: Student,
      path: AppRoutings.Students,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 27,
      component: Users,
      path: AppRoutings.Users,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 28,
      component: EnrollmentSummary,
      path: AppRoutings.EnrollmentSummary,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 29,
      component: StudentDetails,
      path: AppRoutings.StudentsDetails,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 30,
      component: Documents,
      path: AppRoutings.Documents,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 31,
      component: StudentAttendance,
      path: AppRoutings.StudentAttendance,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 32,
      component: StudentDashboard,
      path: AppRoutings.StudentDashboard,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 33,
      component: MyEnrolments,
      path: AppRoutings.MyEnrolment,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 34,
      component: StudentProfile,
      path: AppRoutings.StudentProfile,
      exact: true,
      isProtectedRoute: true,
    },

    {
      id: 35,
      component: StudentDashboardCourseDetail,
      path: AppRoutings.StudentDashboardCourseDetails,
      exact: true,
      isProtectedRoute: true,
    },

    {
      id: 36,
      component: StudentDashboardCourseDetail,
      path: AppRoutings.MyEnrolmentCourseDetails,
      exact: true,
      isProtectedRoute: true,
    },

    {
      id: 41,
      component: ApproveRejectEnrolments,
      path: AppRoutings.EnrollmentApproval,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 42,
      component: Handbook,
      path: AppRoutings.Handbook,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 43,
      component: TermTimetable,
      path: AppRoutings.TermTimetable,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 44,
      component: CourseGuide,
      path: AppRoutings.CourseGuide,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 45,
      component: EducatorHandbook,
      path: AppRoutings.EducatorHandbook,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 46,
      component: Reports,
      path: AppRoutings.StudentReport,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 47,
      component: StaffReport,
      path: AppRoutings.StaffReport,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 48,
      component: CourseReport,
      path: AppRoutings.CourseReport,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 49,
      component: SLPReport,
      path: AppRoutings.SLPReport,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 50,
      component: EducatorDocuments,
      path: AppRoutings.EducatorDocuments,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 51,
      component: StudentManual,
      path: AppRoutings.StudentManual,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 52,
      component: EducatorManual,
      path: AppRoutings.EducatorManual,
      exact: true,
      isProtectedRoute: true,
    },
    {
      id: 53,
      component: AddEditEducator,
      path: AppRoutings.EducatorProfile,
      exact: true,
      isProtectedRoute: true,
    },
  ];

  let redirectPath;

  switch (true) {
    case user?.educatorId !== 0:
      redirectPath = AppRoutings.EducatorsHome;
      break;

    case user?.roleName?.length === 1 && user?.roleName?.includes("Student"):
      redirectPath = AppRoutings.StudentDashboard;
      break;

    default:
      redirectPath = AppRoutings.Dashboard;
  }

  return (
    <Switch>
      {routes
        .filter((allRoutes: IRoute) => !allRoutes.isProtectedRoute)
        .map((route: IRoute) => (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}

      <Layout>
        <Switch>
          {routes
            .filter((allRoutes: IRoute) => allRoutes.isProtectedRoute)
            .map((route: IRoute) => {
              if (isAuthenticated && !isTokenExpired(token)) {
                return (
                  <Route
                    key={route.id}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                );
              }

              return "";
            })}
          <Redirect to={redirectPath} />
        </Switch>
      </Layout>
      <Redirect to={redirectPath} />
    </Switch>
  );
};

const mapStateToProps = (state: AuthState) => {
  return {
    user: getUserSelector(state),
    token: getAuthSelector(state),
    isAuthenticated: checkIsAuthenticated(state),
  };
};

export default connect(mapStateToProps)(Routes);
