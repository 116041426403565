import { saveIcon } from "assets/images";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { getPagePermissions, showLoader } from "utils/helper";
import {
  EditResearchProfileDetailsSuccessPayload,
  ResearchProfileDetailsSuccessPayload,
} from "store/student/types";
import { AppPages } from "utils/enums/app-pages";
import { useForm } from "react-hook-form";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { toast } from "react-toastify";
import {
  RESEARCH_PROFILE_DETAIL_EDIT_SUCCESS_MESSAGE,
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import { IResearchProfileDetailData } from "utils/interfaces/student";
import {
  STUDENT_CURRENT_SERVICE_PROVIDER_MAX_LENGTH,
  STUDENT_ENROLMENT_COB_MAX_LENGTH,
  STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH,
  STUDENT_ENROLMENT_LAH_MAX_LENGTH,
} from "utils/constants/student-enrolment";
import {
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
} from "utils/constants/constant";

interface IResearchProfileDetailsProps {
  researchProfileDetailsRequest: any;
  editResearchProfileDetailRequest: any;
  enrolmentOptionList: IEnrolmentOptions | undefined;
}

interface IResearchProfileDetails {
  id?: number;
  fullName: string;
  hearAboutRC: string;
  eligibilityCriteria: string;
  currentServiceProviders: string;
  atsiIdentity: string;
  disability: string;
  countryOfBirth: string;
  languageAtHome: string;
  ethnicity: string | null;
  genderIdentity: string;
  employmentStatus: string;
  isStuding: string;
  highestCompletedSchoolLevel: string;
  studyLoad: string;
  mrn: string;
  researchCode: string;
  activeCommunityEMR: string;
  isActive: boolean;
}

const ResearchProfileDetails: React.FC<IResearchProfileDetailsProps> = ({
  researchProfileDetailsRequest,
  editResearchProfileDetailRequest,
  enrolmentOptionList,
}) => {
  const location = useLocation<{ studentId: number; isDeleted?: boolean }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const [researchProfileData, setResearchProfileData] =
    useState<IResearchProfileDetailData>();

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IResearchProfileDetails>({
    defaultValues: {
      hearAboutRC: "",
      eligibilityCriteria: "",
      currentServiceProviders: "",
      atsiIdentity: "",
      disability: "",
      genderIdentity: "",
      activeCommunityEMR: "",
      studyLoad: "",
      isStuding: "",
      highestCompletedSchoolLevel: "",
      employmentStatus: "",
    },
  });

  const getCountryOfBirthError = (): string => {
    if (errors.countryOfBirth) {
      if (errors.countryOfBirth.type === "required") {
        return "Country Of Birth is required";
      }
      if (errors.countryOfBirth.type === "maxLength") {
        return `Maximum length of country should be ${STUDENT_ENROLMENT_COB_MAX_LENGTH}`;
      }
      if (errors.countryOfBirth.type === "pattern") {
        return `Country Of Birth ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getHeardAboutRCError = (): string => {
    if (errors.hearAboutRC) {
      if (errors.hearAboutRC.type === "required") {
        return "Heard about RC is required";
      }
    }

    return "";
  };

  const getEligibilityCriteriaError = (): string => {
    if (errors.eligibilityCriteria) {
      if (errors.eligibilityCriteria.type === "required") {
        return "Eligibility Criteria is required";
      }
    }

    return "";
  };

  const getServiceProviderError = (): string => {
    if (errors.currentServiceProviders) {
      if (errors.currentServiceProviders.type === "required") {
        return "Current Service Providers is required";
      }
      if (errors.currentServiceProviders.type === "maxLength") {
        return `Maximum length of Service provider should be ${STUDENT_CURRENT_SERVICE_PROVIDER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getAtsiIdentityError = (): string => {
    if (errors.atsiIdentity) {
      if (errors.atsiIdentity.type === "required") {
        return "AtsiIdentity is required";
      }
    }

    return "";
  };

  const getDisabilityError = (): string => {
    if (errors.disability) {
      if (errors.disability.type === "required") {
        return "Disability is required";
      }
    }

    return "";
  };

  const getGenderIdentityError = (): string => {
    if (errors.genderIdentity) {
      if (errors.genderIdentity.type === "required") {
        return "Gender Identity is required";
      }
    }

    return "";
  };

  const getActiveCommunityEMRError = (): string => {
    if (errors.activeCommunityEMR) {
      if (errors.activeCommunityEMR.type === "required") {
        return "ActiveCommunityEMR is required";
      }
    }

    return "";
  };

  const getLanguageAtHomeError = (): string => {
    if (errors.languageAtHome) {
      if (errors.languageAtHome.type === "maxLength") {
        return `Maximum length of Language should be ${STUDENT_ENROLMENT_LAH_MAX_LENGTH}`;
      }
      if (errors.languageAtHome.type === "pattern") {
        return `Language should be ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEthnicityError = (): string => {
    if (errors.ethnicity) {
      if (errors.ethnicity.type === "maxLength") {
        return `Maximum length of Ethnicity should be ${STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH}`;
      }
      if (errors.ethnicity.type === "pattern") {
        return `Ethnicity should be ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getResearchCodeError = (): string => {
    if (errors.researchCode) {
      if (errors.researchCode.type === "pattern") {
        return `Research Code ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getEmploymentStatusError = (): string => {
    if (errors.employmentStatus) {
      if (errors.employmentStatus.type === "required") {
        return "Employment status is required";
      }
    }

    return "";
  };

  const getHighestCompletedSchoolError = (): string => {
    if (errors.highestCompletedSchoolLevel) {
      if (errors.highestCompletedSchoolLevel.type === "required") {
        return "Highest completed school level is required";
      }
    }

    return "";
  };

  const getCurrentlyStudyingError = (): string => {
    if (errors.isStuding) {
      if (errors.isStuding.type === "required") {
        return "Currently studying is required";
      }
    }

    return "";
  };

  const getStudyLoadError = (): string => {
    if (errors.studyLoad) {
      if (errors.studyLoad.type === "required") {
        return "Study load is required";
      }
    }

    return "";
  };

  const setStudentFormValues = (researchProfileDetails: any) => {
    reset((formValues) => ({
      ...formValues,
      fullName: researchProfileDetails.fullName,
      hearAboutRC: researchProfileDetails.hearAboutRC,
      eligibilityCriteria: researchProfileDetails.eligibilityCriteria,
      currentServiceProviders: researchProfileDetails.currentServiceProviders,
      atsiIdentity: researchProfileDetails.atsiIdentity,
      disability: researchProfileDetails.disability,
      countryOfBirth: researchProfileDetails.countryOfBirth,
      languageAtHome: researchProfileDetails.languageAtHome,
      ethnicity: researchProfileDetails.ethnicity,
      genderIdentity: researchProfileDetails.genderIdentity,
      mrn: researchProfileDetails.mrn,
      researchCode: researchProfileDetails.researchCode,
      activeCommunityEMR: researchProfileDetails.activeCommunityEMR,
      employmentStatus: researchProfileDetails.employmentStatus,
      isStuding: researchProfileDetails.isStuding,
      highestCompletedSchoolLevel:
        researchProfileDetails.highestCompletedSchoolLevel,
      studyLoad: researchProfileDetails.studyLoad,
    }));
  };
  const onResearchProfileDataSuccess = (
    response: ResearchProfileDetailsSuccessPayload
  ) => {
    setStudentFormValues(response.ResearchProfileDetails);
    setResearchProfileData(response.ResearchProfileDetails);
  };

  const getResearchProfileData = () => {
    if (researchProfileDetailsRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onResearchProfileDataSuccess,
      };

      researchProfileDetailsRequest(payload);
    }
  };

  const handleCancelClick = () => {
    setStudentFormValues(researchProfileData);
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (location?.state.studentId && permission.edit) {
      getResearchProfileData();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditResearchProfileDetailSuccess = (
    response: EditResearchProfileDetailsSuccessPayload
  ) => {
    if (response.success) {
      toast.success(RESEARCH_PROFILE_DETAIL_EDIT_SUCCESS_MESSAGE);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const onSubmit = async (data: IResearchProfileDetails) => {
    if (location.state.studentId && !location.state.isDeleted) {
      if (editResearchProfileDetailRequest) {
        showLoader();
        const payload = {
          values: {
            id: location.state.studentId,
            eligibilityCriteria: data.eligibilityCriteria,
            hearAboutRC: data.hearAboutRC,
            atsiIdentity: data.atsiIdentity,
            disability: data.disability,
            countryOfBirth: data.countryOfBirth,
            languageAtHome: data.languageAtHome,
            ethnicity: data.ethnicity,
            genderIdentity: data.genderIdentity,
            currentServiceProviders: data.currentServiceProviders,
            researchCode: data.researchCode,
            mrn: data.mrn,
            activeCommunityEMR: data.activeCommunityEMR,
            studyLoad: data.studyLoad,
            isStuding: data.isStuding,
            highestCompletedSchoolLevel: data.highestCompletedSchoolLevel,
            employmentStatus: data.employmentStatus,
          },
          callback: onEditResearchProfileDetailSuccess,
        };
        editResearchProfileDetailRequest(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="std-details-card">
        <Typography variant="h3" className="attnd-heading">
          Research Profile Details
        </Typography>
        <Grid container columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="std-conn"
              label="Student (Connection)"
              fullWidth
              variant="outlined"
              disabled
              InputLabelProps={{
                shrink: Boolean(watch("fullName")),
              }}
              {...register("fullName")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} sx={{ display: "none" }}>
            <TextField
              id="num-ques"
              label="No. of Questionnaires Completed"
              fullWidth
              variant="outlined"
              defaultValue="01"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="find-rc-label" shrink={!!watch("hearAboutRC")}>
                How did you hear about RC? <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="find-rc-label"
                id="find-rc"
                label={
                  <>
                    How did you hear about RC?{" "}
                    <span className="color-red">*</span>
                  </>
                }
                value={watch("hearAboutRC")}
                error={!!errors.hearAboutRC}
                {...register("hearAboutRC", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.hearAboutRC?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.hearAboutRC && (
                <FormHelperText error>{getHeardAboutRCError()}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="elg-criteria-label"
                shrink={!!watch("eligibilityCriteria")}
              >
                Eligibility Criteria <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="elg-criteria-label"
                id="elg-criteria"
                label={
                  <>
                    Eligibility Criteria <span className="color-red">*</span>
                  </>
                }
                value={watch("eligibilityCriteria")}
                error={!!errors.eligibilityCriteria}
                {...register("eligibilityCriteria", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.eligibilityCriteria?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.eligibilityCriteria && (
                <FormHelperText error>
                  {getEligibilityCriteriaError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="svc-provider-label"
                shrink={!!watch("eligibilityCriteria")}
              >
                Service Provider <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="svc-provider-label"
                id="svc-provider"
                label={
                  <>
                    Service Provider <span className="color-red">*</span>
                  </>
                }
                value={watch("currentServiceProviders")}
                error={!!errors.currentServiceProviders}
                {...register("currentServiceProviders", {
                  required: true,
                  maxLength: STUDENT_CURRENT_SERVICE_PROVIDER_MAX_LENGTH,
                })}
              >
                {enrolmentOptionList?.currentServiceProviders?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.currentServiceProviders && (
                <FormHelperText error>
                  {getServiceProviderError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="atsci-id-label" shrink={!!watch("atsiIdentity")}>
                Aboriginal/Torres Strait Islander Identity{" "}
                <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="atsci-id-label"
                id="atsci-id"
                label={
                  <>
                    Aboriginal/Torres Strait Islander Identity{" "}
                    <span className="color-red">*</span>
                  </>
                }
                value={watch("atsiIdentity")}
                error={!!errors.atsiIdentity}
                {...register("atsiIdentity", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.atsiIdentity?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.atsiIdentity && (
                <FormHelperText error>{getAtsiIdentityError()}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="disability-label" shrink={!!watch("disability")}>
                Disability <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="disability-label"
                id="disability"
                label={
                  <>
                    Disability <span className="color-red">*</span>
                  </>
                }
                value={watch("disability")}
                error={!!errors.disability}
                {...register("disability", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.disability?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.disability && (
                <FormHelperText error>{getDisabilityError()}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="cob"
              label={
                <>
                  Country of Birth <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("countryOfBirth")),
              }}
              error={!!errors.countryOfBirth}
              helperText={getCountryOfBirthError()}
              {...register("countryOfBirth", {
                required: true,
                maxLength: STUDENT_ENROLMENT_COB_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="home-lang"
              label="Language at home"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("languageAtHome")),
              }}
              error={!!errors.languageAtHome}
              helperText={getLanguageAtHomeError()}
              {...register("languageAtHome", {
                maxLength: STUDENT_ENROLMENT_LAH_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="ethnicity"
              label="Ethnicity"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("ethnicity")),
              }}
              error={!!errors.ethnicity}
              helperText={getEthnicityError()}
              {...register("ethnicity", {
                maxLength: STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="genderid-label"
                shrink={!!watch("genderIdentity")}
              >
                Gender Identity <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="genderid-label"
                id="genderid"
                label={
                  <>
                    Gender Identity <span className="color-red">*</span>
                  </>
                }
                value={watch("genderIdentity")}
                error={!!errors.genderIdentity}
                {...register("genderIdentity", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.genderIdentity?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.genderIdentity && (
                <FormHelperText error>
                  {getGenderIdentityError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              id="research-code"
              label="Research Code"
              fullWidth
              variant="outlined"
              error={!!errors.researchCode}
              helperText={getResearchCodeError()}
              InputLabelProps={{
                shrink: Boolean(watch("researchCode")),
              }}
              {...register("researchCode", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="active-emr-label"
                shrink={!!watch("activeCommunityEMR")}
              >
                Active Comm&#39;ty eMR &#40;at enrolment&#41;{" "}
                <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="active-emr-label"
                id="active-emr"
                label={
                  <>
                    Active Comm&#39;ty eMR &#40;at enrolment&#41;{" "}
                    <span className="color-red">*</span>
                  </>
                }
                value={watch("activeCommunityEMR")}
                error={!!errors.activeCommunityEMR}
                {...register("activeCommunityEMR", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.activeCommunityEMR?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.activeCommunityEMR && (
                <FormHelperText error>
                  {getActiveCommunityEMRError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="empstatus-label"
                shrink={!!watch("employmentStatus")}
              >
                Employment Status <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="empstatus-label"
                id="empstatus"
                label="Employment Status*"
                value={watch("employmentStatus")}
                error={!!errors.employmentStatus}
                {...register("employmentStatus", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.employmentStatus?.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
              {!!errors.employmentStatus && (
                <FormHelperText error>
                  {getEmploymentStatusError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="schoollvel-label"
                shrink={!!watch("highestCompletedSchoolLevel")}
              >
                Highest Completed School Level{" "}
                <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="schoollvel-label"
                id="schoollvel"
                label="Highest Completed School Level*"
                value={watch("highestCompletedSchoolLevel")}
                error={!!errors.highestCompletedSchoolLevel}
                {...register("highestCompletedSchoolLevel", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.highestCompletedSchoolLevel?.map(
                  (option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  )
                )}
              </Select>
              {!!errors.highestCompletedSchoolLevel && (
                <FormHelperText error>
                  {getHighestCompletedSchoolError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="currentstudy-label" shrink={!!watch("isStuding")}>
                Currently Studying <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="currentstudy-label"
                id="currentstudy"
                label="Currently Studying*"
                value={watch("isStuding")}
                error={!!errors.isStuding}
                {...register("isStuding", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.currentlyStuding?.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
              {!!errors.isStuding && (
                <FormHelperText error>
                  {getCurrentlyStudyingError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="studyload-label" shrink={!!watch("studyLoad")}>
                Study Load <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="studyload-label"
                id="studyload"
                label="Study Load*"
                value={watch("studyLoad")}
                error={!!errors.studyLoad}
                {...register("studyLoad", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.studyLoad?.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
              {!!errors.studyLoad && (
                <FormHelperText error>{getStudyLoadError()}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Box className="btn-group">
          <Button
            disabled={location.state.isDeleted}
            variant="contained"
            className="btn-save"
            type="submit"
          >
            <img src={saveIcon} alt="save" />
            Save
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ResearchProfileDetails;
