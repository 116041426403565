import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  courseListIcon,
  courseListLightIcon,
  courseListSecondaryIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import React, { useCallback, useEffect, useState } from "react";
import {
  GetStaffReportDetailsListSuccessPayload,
  PrintStaffReportSuccessPayload,
  StaffReportListSuccessPayload,
} from "store/staffReport/types";
import {
  base64ToArrayBuffer,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  STAFF_DETAILS_REPORT_TOOLTIP_TITLE,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { IRoleModulePermission } from "store/role/types";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import {
  IStaffReport,
  IStaffReportContainerState,
  IStaffReportContainerDispatch,
  IStaffReportListMeta,
  IStaffReportPagePayloadValues,
  IEnhancedTableProps,
  IStaffReportFilter,
  IStaffReportDetailsPayloadValues,
  IStaffReportDetailsList,
} from "utils/interfaces/staff-report";
import { toast } from "react-toastify";
import { SELECT_MIN_ONE_FIELD } from "utils/constants/Messages";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { EnrolmentOptionListSuccessPayload } from "store/studentEnrolment/types";
import OverlayBox from "components/OverlayBox";
import { ExportToCsv } from "export-to-csv";
import StaffReportFilter from "./Filter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IStaffReport) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type StaffReportProps = IStaffReportContainerState &
  IStaffReportContainerDispatch;

const StaffReport: React.FC<StaffReportProps> = (props) => {
  const [staffReportList, setStaffReportList] = useState<IStaffReport[]>();
  const [staffReportListMeta, setStaffReportListMeta] =
    useState<IStaffReportListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IStaffReport>("staffName");
  const [filterBox, setFilterBox] = useState(false);
  const [filteredData, setFilteredData] = useState<IStaffReportFilter>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();
  const [exportMenu, setExportMenu] = React.useState<null | HTMLElement>(null);
  const exportOpen = Boolean(exportMenu);
  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenu(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportMenu(null);
  };
  const user = useSelector((state: any) => state?.auth?.user);
  const history = useHistory();

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IStaffReport) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );
  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    const { enrolmentOptionListRequest } = props;
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onStaffReportListSuccess = (
    response: StaffReportListSuccessPayload
  ) => {
    setStaffReportList(response.staffReportList);
    setStaffReportListMeta(response.staffReportListPagination);
    hideLoaderForBatchApiCall();
  };

  const getStaffReportList = async (
    filterData?: IStaffReportFilter,
    pageNum?: number
  ) => {
    const { staffReportListRequest } = props;
    if (staffReportListRequest) {
      showLoaderForBatchApiCall();
      const values: IStaffReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );

      if (filterData?.currentRole) {
        values.currentRole = filterData?.currentRole;
      }
      if (filterData?.terms) {
        values.terms = filterData?.terms;
      }
      if (filterData?.employmentStatus) {
        values.employmentStatus = filterData?.employmentStatus;
      }
      if (filterData?.workEnvironment) {
        values.workEnvironment = filterData?.workEnvironment;
      }
      if (filterData?.site) {
        values.site = filterData?.site;
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onStaffReportListSuccess,
      };

      staffReportListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Reports);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getStaffReportList(filteredData, 1);
      } else if (filteredData) {
        getStaffReportList(filteredData);
      } else {
        getStaffReportList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleFilterApplyClick = (formData: IStaffReportFilter) => {
    if (
      !formData.terms?.length &&
      !formData.workEnvironment?.length &&
      !formData.currentRole?.length &&
      !formData.site?.length &&
      !formData.employmentStatus?.length
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      if (pageNo === DEFAULT_PAGE_NO) {
        getStaffReportList(formData, 1);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };
  const csvFileHeadersStaffReport = [
    "Name",
    "Work Environment",
    "Site",
    "Employment Status",
    "Current Role",
    "Courses Enrolled",
    "Courses Attended",
  ];

  const getEnrolmentsModifiedData = (list: IStaffReport[]) => {
    const data = list?.map((report: IStaffReport) => {
      return {
        Name: report.staffName,
        "Work Environment": report.workEnvironment,
        Site: report.site,
        "Employment Status": report.employmentStatus,
        "Current Role": report.currentRole,
        "Courses Enrolled": report.courseEnrolled,
        "Courses Attended": report.courseAttended,
      };
    });

    return data;
  };

  const onExportExcelStaffReportListSuccess = (
    response: StaffReportListSuccessPayload
  ) => {
    const modifiedEnrolmentsData = getEnrolmentsModifiedData(
      response.staffReportList
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeadersStaffReport,
      filename: "Staff Report",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedEnrolmentsData);
  };

  const getExportExcelStaffReport = () => {
    const { staffReportListRequest } = props;
    if (staffReportListRequest) {
      showLoader();
      const values: IStaffReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );

      if (filteredData?.currentRole) {
        values.currentRole = filteredData?.currentRole;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }
      if (filteredData?.employmentStatus) {
        values.employmentStatus = filteredData?.employmentStatus;
      }
      if (filteredData?.workEnvironment) {
        values.workEnvironment = filteredData?.workEnvironment;
      }
      if (filteredData?.site) {
        values.site = filteredData?.site;
      }

      const payload = {
        values,
        callback: onExportExcelStaffReportListSuccess,
      };

      staffReportListRequest(payload);
    }
  };

  const getStaffReportDetailsModifiedData = (
    list: Array<IStaffReportDetailsList>
  ) => {
    const data = list?.map((details: IStaffReportDetailsList) => {
      return {
        Name: details.name,
        Term: details.term,
        Course: details.course,
        "Work Environment": details.workEnvironment,
        Site: details.site,
        "Employment Status": details.employmentStatus,
        "Current Role": details.currentRole,
      };
    });

    return data;
  };

  const csvFileHeadersStaffDetails = [
    "Name",
    "Term",
    "Course",
    "Work Environment",
    "Site",
    "Employment Status",
    "Current Role",
  ];
  const onStaffReportDetailsSuccess = (
    response: GetStaffReportDetailsListSuccessPayload
  ) => {
    const modifiedStudentDetailsData = getStaffReportDetailsModifiedData(
      response.staffReportDetailList
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeadersStaffDetails,
      filename: "Staff Details",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedStudentDetailsData);
  };

  const getStaffReportDetails = (
    studentId: number,
    isCourseAttended: boolean
  ) => {
    const { getStaffReportDetailsRequest } = props;
    if (getStaffReportDetailsRequest) {
      showLoader();
      const values: IStaffReportDetailsPayloadValues = {
        studentId,
        isCourseAttended,
      };
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }
      const payload = {
        values,
        callback: onStaffReportDetailsSuccess,
      };

      getStaffReportDetailsRequest(payload);
    }
  };

  const onPrintStaffReportSuccess = (
    response: PrintStaffReportSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const getPrintStaffReport = () => {
    const { printStaffReportRequest } = props;
    if (printStaffReportRequest) {
      showLoader();
      const values: IStaffReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );

      if (filteredData?.currentRole) {
        values.currentRole = filteredData?.currentRole;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }
      if (filteredData?.employmentStatus) {
        values.employmentStatus = filteredData?.employmentStatus;
      }
      if (filteredData?.workEnvironment) {
        values.workEnvironment = filteredData?.workEnvironment;
      }
      if (filteredData?.site) {
        values.site = filteredData?.site;
      }

      const payload = {
        values,
        callback: onPrintStaffReportSuccess,
      };

      printStaffReportRequest(payload);
    }
  };

  useEffect(() => {
    getEnrolmentOptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <div className="wrapper">
          <Box
            className="overlay"
            onClick={() => document.body.classList.toggle("sidebar-toggle")}
          />
          <Box
            className="filter-overlay"
            onClick={() => {
              document.body.classList.toggle("filter-open");
              setFilterBox(false);
            }}
          />
          {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Report - Staff
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                id="export-btn"
                aria-controls={exportOpen ? "export-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={exportOpen ? "true" : undefined}
                onClick={handleExportClick}
                disableElevation
                variant="outlined"
                className="btn-export"
              >
                Export
              </Button>
              <Menu
                id="export-menu"
                anchorEl={exportMenu}
                open={exportOpen}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "export-button",
                }}
                className="export-menu"
              >
                <MenuItem
                  disabled={!pagePermission?.view}
                  onClick={getExportExcelStaffReport}
                  title="Excel"
                >
                  <Button>Excel</Button>
                </MenuItem>
                <MenuItem
                  disabled={!pagePermission?.view}
                  onClick={getPrintStaffReport}
                  title="PDF"
                >
                  <Button>PDF</Button>
                </MenuItem>
              </Menu>
              <Button
                variant="outlined"
                className="btn-filter report-filter"
                onClick={() => {
                  setFilterBox(true);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={filterPrimaryIcon} alt="filter" className="default" />
                <img src={filterLightIcon} alt="filter" className="active" />
                <span className="text">Filter</span>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Name"
                          keyName="staffName"
                          sxStyle={{
                            maxWidth: "156px",
                            width: "156px",
                            minWidth: "156px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Work Environment"
                          keyName="workEnvironment"
                          sxStyle={{
                            maxWidth: "216px",
                            width: "216px",
                            minWidth: "216px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Site"
                          keyName="site"
                          sxStyle={{
                            maxWidth: "216px",
                            width: "216px",
                            minWidth: "216px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Employment Status"
                          keyName="employmentStatus"
                          sxStyle={{
                            maxWidth: "216px",
                            width: "216px",
                            minWidth: "216px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Current Role"
                          keyName="currentRole"
                          sxStyle={{
                            maxWidth: "216px",
                            width: "216px",
                            minWidth: "216px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Courses Enrolled"
                          keyName="courseEnrolled"
                          sxStyle={{
                            maxWidth: "186px",
                            width: "186px",
                            minWidth: "186px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Courses Attended"
                          keyName="courseAttended"
                          sxStyle={{
                            maxWidth: "186px",
                            width: "186px",
                            minWidth: "186px",
                            textAlign: "center",
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {staffReportList?.map((row) => (
                        <TableRow key={row?.studentId}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.staffName}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.workEnvironment}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.site}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.employmentStatus}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.currentRole}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                            align="center"
                          >
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={STAFF_DETAILS_REPORT_TOOLTIP_TITLE}
                              arrow
                            >
                              <Button
                                className="btn-course"
                                variant="outlined"
                                disabled={
                                  !row.courseEnrolled || !pagePermission?.view
                                }
                                onClick={() =>
                                  !row.courseEnrolled
                                    ? null
                                    : getStaffReportDetails(
                                        row.studentId,
                                        false
                                      )
                                }
                              >
                                <img
                                  src={
                                    !row.courseEnrolled
                                      ? courseListSecondaryIcon
                                      : courseListIcon
                                  }
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={courseListLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row?.courseEnrolled}
                                </Typography>
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                            align="center"
                          >
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={STAFF_DETAILS_REPORT_TOOLTIP_TITLE}
                              arrow
                            >
                              <Button
                                className="btn-course"
                                variant="outlined"
                                disabled={
                                  !row.courseAttended || !pagePermission?.view
                                }
                                onClick={() =>
                                  !row.courseAttended
                                    ? null
                                    : getStaffReportDetails(row.studentId, true)
                                }
                              >
                                <img
                                  src={
                                    !row.courseAttended
                                      ? courseListSecondaryIcon
                                      : courseListIcon
                                  }
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={courseListLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row?.courseAttended}
                                </Typography>
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {staffReportList && staffReportList.length > 0 ? (
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        {PAGE_SIZES?.map((pageSize) => (
                          <MenuItem
                            key={pageSize.value}
                            value={pageSize.value}
                            selected={pageSize?.selected}
                          >
                            {pageSize.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" component="span">
                        {getPaginationDetailText(
                          staffReportListMeta,
                          pageNo,
                          page
                        )}
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={staffReportListMeta?.totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={pageNo}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <NoRecords />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
      {filterBox && (
        <StaffReportFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          setAppliedFilter={setAppliedFilter}
          getReportList={getStaffReportList}
          enrolmentOptionList={enrolmentOptionList}
        />
      )}
    </>
  );
};

export default StaffReport;
