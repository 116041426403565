export const INTERNAL_SERVER_ERROR = "Internal Server Error";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const UNAUTHORIZED = "Unauthorized";

export const FOOTER_TEXT =
  "©2023 South Eastern Sydney Recovery College. All rights reserved.";

export const TOKEN_EXPIRES_IN_DAYS = 7;
export const TOKEN_EXPIRES_IN_MINUTES = 120;

export const PAGE_SIZES = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20, selected: true },
  { value: 50, label: 50 },
];

export const TERM_NUMBER = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

export const TERM_STATUS = [
  { value: 1, label: "Current Term" },
  { value: 2, label: "Future Term" },
  { value: 3, label: "Past Term" },
];

export const COURSE_STREAM = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const COURSE_AVAILABILITY = [
  { value: 2, label: "No" },
  { value: 1, label: "Yes" },
];

export const LOCATION_MAX_LENGTH = 500;

export const COURSE_NO_MAX_LENGTH = 50;
export const COURSE_NAME_MAX_LENGTH = 100;

export const TERM_NAME_MAX_LENGTH = 50;

export const ROLE_NAME_MAX_LENGTH = 50;

export const FIRST_NAME_MAX_LENGTH = 50;
export const LAST_NAME_MAX_LENGTH = 50;
export const ADDRESS_MAX_LENGTH = 250;

export const MAX_INT_VALUE = 2147483647;

export const INVALID_CHAR_FOR_NUMBER_TYPE = ["-", "e", "+", "E"];

export const SCO_CERTIFICATE = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

export const SCO_BLENDED_COURSE = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

export const SCO_STATUS = [
  { value: 2, label: "Cancelled" },
  { value: 1, label: "Confirmed" },
];

export const SCO_REPEAT = [
  { value: 1, label: "Daily" },
  { value: 2, label: "Weekly" },
];

export const SCO_REPEAT_END = [
  { value: 1, label: "After" },
  { value: 3, label: "On" },
];

export const SCO_DOCUMENT_TYPE = [
  { id: 1, key: "BlankEvaluationForm", name: "Student Evaluation Form" },
  {
    id: 2,
    key: "CompletedEvaluationForm",
    name: "Completed Evaluation Form (Quantitative Research)",
  },
  {
    id: 3,
    key: "CompletedLearningOutcomesForm",
    name: "Completed Learning Outcomes Form (Quantitative Research)",
  },
  { id: 4, key: "EducatorFeedbackForm", name: "Educator Feedback Form" },
  { id: 5, key: "ResearchersSummary", name: "Researcher's Summary" },
];

export const DATE_FORMAT_WITH_TIME = "YYYY-MM-DDTHH:mm:ss";
export const DATE_FORMAT = "DD-MM-YYYY";
export const TIME_FORMAT = "HH:mm";

export const DEFAULT_PAGE_SIZE = "20";
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_TEN_PAGE_SIZE = "10";

export const SESSION_DAY_STATUS = [
  { value: 1, label: "Active" },
  { value: 2, label: "Cancelled" },
];

export const DATE_PICKER_FORMAT = "DD/MM/YYYY";

export const DATE_VIEW_FORMAT = "DD MMM YYYY";

export const TIME_VIEW_FORMAT = "hh:mm A";
export const PDF_FILE_TYPE = "application/pdf";
export const DOC_FILE_TYPE = "application/msword";
export const DOCX_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const SCO_DOC_SUPPORTED_TYPES = [
  PDF_FILE_TYPE,
  DOC_FILE_TYPE,
  DOCX_FILE_TYPE,
];
export const DATE_OF_BIRTH_FORMAT = "D, MMM. YYYY";

export const DOCUMENT_NAME_MAX_LENGTH = 500;
export const DOCUMENT_TYPE = [
  { value: "Admin", label: "Admin" },
  { value: "CourseGuide", label: "Course Guide" },
  { value: "EducatorDocument", label: "Educator" },
  { value: "EducatorHandBook", label: "Educator Handbook" },
  { value: "EducatorManual", label: "Educator Manual" },
  { value: "Information", label: "Information" },
  { value: "Others", label: "Others" },
  { value: "Policy", label: "Policy" },
  { value: "SLP", label: "SLP" },
  { value: "HandBook", label: "Student Handbook" },
  { value: "StudentManual", label: "Student Manual" },
  { value: "TermTimetable", label: "Term Timetable" },
];
export const WARNING_DOCUMENT_TYPES = [
  "CourseGuide",
  "EducatorHandBook",
  "HandBook",
  "TermTimetable",
  "StudentManual",
  "EducatorManual",
];

export const DOCUMENT_DESCRIPTION_MAX_LENGTH = 1024;
export const STUDENT_ROLE_ID = 2;
export const EDUCATOR_ROLE_ID = 3;
export const RESTRICTED_FILE_TYPES = [
  "application/vnd.oasis.opendocument.text",
];

export const UPCOMING_COURSE_TYPE = "My Upcoming Courses";
export const PAST_COURSE_TYPE = "My Past Courses";
export const COURSE_OFFERING_TYPE = "Course Offerings";

export const STUDENT_NOTE_MAX_LENGTH = 250;
export const MY_ENROLMENT_COURSES = "My Enrolments";

export const APPROVED_STATUS = "Approved";
export const REJECTION_NOTE_MAX_LENGTH = 250;
export const COURSE_STATUS = "WaitListed";
export const ALPHABETIC_INPUT_REGEX = /^[a-zA-Z]*$/;
export const MAX_SIZE_IN_BYTES = 1 * 1024 * 1024 * 1024;
export const ALPHABETIC_INPUT_WITH_SPACE_REGEX = /^[A-Za-z ]+[A-Za-z]*$/;
export const ENROLMENT_STATUS = [
  { value: 1, label: "Confirmed" },
  { value: 0, label: "Pending" },
  { value: 3, label: "Rejected" },
  { value: 2, label: "Waitlisted" },
  { value: 4, label: "Withdrawn" },
];

export const CERTIFICATE_NAME = "Certificate.pdf";
export const STUDENT_ROLE_NAME = "student";
export const EDUCATOR_ROLE_NAME = "educator";
export const ROLE_TYPE_USER_FILTER = ["student", "educator"];
export const INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX =
  /^(?! )[A-Za-z0-9!@#$%^&*()_+-=\]{};':"\\|,.<>/?]*(?: [A-Za-z0-9!@#$%^&*()_+-=\]{};':"\\|,.<>/?]+)*(?<!  {0})$/;
export const INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX =
  /^(?! )[A-Za-z0-9]*(?: [A-Za-z0-9]+)*(?<!  {0})$/;
export const INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX =
  /^(?! )[A-Za-z]*(?: [A-Za-z]+)*(?<!  {0})$/;

export const EDIT_TOOLTIP_TITLE = "Edit";
export const DELETE_TOOLTIP_TITLE = "Delete";
export const VIEW_TOOLTIP_TITLE = "View Details";
export const CANCEL_TOOLTIP_TITLE = "Cancel";
export const CLOSE_TOOLTIP_TITLE = "Close";
export const CLEAR_TOOLTIP_TITLE = "Clear search";
export const VIEW_CANCELLATION_COMMENT_TOOLTIP_TITLE = "View Comment";
export const BLENDED_FACE_TO_FACE_ONLINE = " - Blended (Face To Face / Online)";
export const FACE_TO_FACE = "Face To Face";
export const ROLE_NAME = "RoleName";
export const STUDENT_DETAILS_ENROLMENT_REPORT_TOOLTIP_TITLE =
  "Download Student Details";
export const STAFF_DETAILS_REPORT_TOOLTIP_TITLE = "Download Staff Details";
export const STUDENT_DOCUMENT_PATH = "Student";
export const DOCUMENT_PATH = "Documents";
