import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  IAttendanceDetails,
  IAttendanceStatus,
  IEducatorHomeDetailsContainerDispatch,
  IEducatorHomeDetailsContainerState,
  IEducatorHomeScoDetails,
  IHomeScoDocument,
  IHomeScoEducator,
  IHomeScoSession,
} from "utils/interfaces/educator-home";
import { ScheduledOfferingDetailsSuccessPayload } from "store/educatorHome/types";
import {
  base64ToArrayBuffer,
  getPagePermissions,
  getUploadedScoDocumentType,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { useHistory, useLocation } from "react-router-dom";
import {
  DeleteDocumentSuccessPayload,
  DownloadDocumentSuccessPayload,
  FillStudentAttendanceSuccessPayload,
  GetSCOStudentAttendanceListSuccessPayload,
  UploadDocumentSuccessPayload,
} from "store/scheduledOffering/types";
import saveAs from "file-saver";
import {
  deleteDocumentRequest,
  downloadDocumentRequest,
  uploadDocumentRequest,
} from "store/scheduledOffering/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import {
  ATTENDANCE_FILLED_SUCCESS_MESSAGE,
  DOC_DELETE_SUCCESS_MSG,
  DOC_UPLOAD_SUCCESS_MSG,
} from "utils/constants/Messages";
import NoRecords from "components/NoRecords";
import { IDeleteDocDetails } from "utils/interfaces/scheduled-offering";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { AppPages } from "utils/enums/app-pages";
import EducatorHomeEnrollments from "./EducatorHomeEnrollments";
import EducatorsSection from "./EducatorsSection";
import DocumentsSection from "./DocumentsSection";
import TakeAttendanceSection from "./TakeAttendanceSection";
import SCODetailsSection from "./SCODetailsSection";

export type EducatorsHomeDetailsProps = IEducatorHomeDetailsContainerState &
  IEducatorHomeDetailsContainerDispatch;

const EducatorsHomeDetails: React.FC<EducatorsHomeDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ scoId: number }>();
  const user = useSelector((state: any) => state?.auth?.user);
  const [attendance, setAttendance] = useState(false);
  const [scheduledOfferingDetails, setScheduledOfferingDetails] =
    useState<IEducatorHomeScoDetails>();
  const [documentsList, setDocumentsList] = useState<IHomeScoDocument[]>([]);
  const [educatorsList, setEducatorsList] = useState<IHomeScoEducator[]>([]);
  const [sessionsList, setSessionsList] = useState<IHomeScoSession[]>([]);
  const [isOpenDeleteDocModal, setIsOpenDeleteDocModal] = useState(false);
  const [deleteDocData, setDeleteDocData] = useState<IDeleteDocDetails>();
  const [attendanceStatus, setAttendanceStatus] = useState<IAttendanceStatus[]>(
    []
  );
  const [attendanceRows, setAttendanceRows] = useState<IAttendanceDetails[]>(
    []
  );

  const {
    enroledStudentListForEducatorHomeRequest,
    SCOStudentAttendanceListRequest,
    fillStudentAttendanceRequest,
  } = props;

  const onSCOStudentAttendanceListSuccess = (
    response: GetSCOStudentAttendanceListSuccessPayload
  ) => {
    setAttendanceRows(response.studentAttendanceList);
    hideLoaderForBatchApiCall();
  };

  const getSCOStudentAttendanceList = (
    sessionId: number,
    courseOfferingId: number
  ) => {
    if (SCOStudentAttendanceListRequest) {
      showLoaderForBatchApiCall();
      const values = { sessionId, courseOfferingId };
      const payload = {
        values,
        callback: onSCOStudentAttendanceListSuccess,
      };
      SCOStudentAttendanceListRequest(payload);
    }
  };
  const [sessionId, setSessionId] = useState<number>();

  const handleAttendance = (courseOfferingSessionId: number) => {
    setAttendance(true);
    setSessionId(courseOfferingSessionId);
    getSCOStudentAttendanceList(courseOfferingSessionId, location.state.scoId);
  };
  const handleAttendanceClose = () => setAttendance(false);

  useEffect(() => {
    const updatedStatus = attendanceRows.map((row) => ({
      studentCourseEnrolmentId: row?.studentCourseEnrolmentId,
      isPresent: row?.isPresent || false,
    }));
    setAttendanceStatus(updatedStatus);
  }, [attendanceRows]);

  const handleCheckboxChange = (index: number) => {
    setAttendanceStatus((prevStatus) => {
      const updatedStatus = [...prevStatus];
      updatedStatus[index].isPresent = !updatedStatus[index].isPresent;

      return updatedStatus;
    });
  };

  const onFillStudentAttendanceSuccess = (
    response: FillStudentAttendanceSuccessPayload
  ) => {
    if (response.success) {
      toast.success(ATTENDANCE_FILLED_SUCCESS_MESSAGE);
      handleAttendanceClose();
      const updatedSessionsList = sessionsList.map((session) => {
        if (session.id === sessionId) {
          return {
            ...session,
            isAttendanceFilled: true,
          };
        }

        return session;
      });

      setSessionsList(updatedSessionsList);
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleSaveAttendance = () => {
    if (fillStudentAttendanceRequest) {
      showLoader();
      const payload = {
        values: {
          courseOfferingId: location.state.scoId,
          sessionId: sessionId!,
          attendanceLists: attendanceStatus,
        },
        callback: onFillStudentAttendanceSuccess,
      };
      fillStudentAttendanceRequest(payload);
    }
  };

  const onScheduledOfferingDetailsSuccess = (
    response: ScheduledOfferingDetailsSuccessPayload
  ) => {
    setScheduledOfferingDetails(response.scheduledOfferingDetails);
    setEducatorsList(
      response.scheduledOfferingDetails.educatorHomeCourseEducators
    );
    setDocumentsList(
      response.scheduledOfferingDetails.educatorHomeCourseDocuments
    );
    setSessionsList(
      response.scheduledOfferingDetails.educatorHomeCourseSessions
    );
  };

  const getCourseOfferingDetails = () => {
    const { scheduledOfferingDetailsRequest } = props;

    if (scheduledOfferingDetailsRequest) {
      showLoader();
      const { scoId } = location.state;

      const payload = {
        values: { id: scoId, userId: user.id },
        callback: onScheduledOfferingDetailsSuccess,
      };

      scheduledOfferingDetailsRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.EducatorHome
    );
    if (location.state?.scoId) {
      if (permission?.edit) {
        getCourseOfferingDetails();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownloadDocumentSuccess = (
    response: DownloadDocumentSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.document);
    const docType = getUploadedScoDocumentType(response.documentName);
    const file = new Blob([item], {
      type: docType,
    });

    saveAs(file, response.documentName);
  };

  const onDownloadDocument = (
    name: string,
    type: number,
    courseOfferingID: number
  ) => {
    showLoader();

    const payload = {
      values: { name, type, courseOfferingID },
      callback: onDownloadDocumentSuccess,
    };

    dispatch(downloadDocumentRequest(payload));
  };

  const onUploadDocumentSuccess = (response: UploadDocumentSuccessPayload) => {
    if (response.success) {
      toast.success(DOC_UPLOAD_SUCCESS_MSG);
      getCourseOfferingDetails();
    }
  };

  const onUploadDocument = (
    name: string,
    type: number,
    courseOfferingID: number,
    document: any
  ) => {
    showLoader();

    const payload = {
      values: { name, type, courseOfferingID, document },
      callback: onUploadDocumentSuccess,
    };

    dispatch(uploadDocumentRequest(payload));
  };

  const handleDeleteDocModal = (deleteData: IDeleteDocDetails) => {
    setDeleteDocData(deleteData);
    setIsOpenDeleteDocModal(true);
  };

  const handleDeleteDocCloseModal = () => {
    setDeleteDocData({
      docId: 0,
      type: 0,
      courseOfferingID: 0,
    });
    setIsOpenDeleteDocModal(false);
  };

  const onDeleteDocumentSuccess = (response: DeleteDocumentSuccessPayload) => {
    if (response.success) {
      toast.success(DOC_DELETE_SUCCESS_MSG);
      handleDeleteDocCloseModal();
      getCourseOfferingDetails();
    }
  };

  const onDeleteDocument = () => {
    if (deleteDocData) {
      showLoader();

      const payload = {
        values: {
          id: deleteDocData?.docId,
          type: deleteDocData?.type,
          courseOfferingID: deleteDocData?.courseOfferingID,
        },
        callback: onDeleteDocumentSuccess,
      };

      dispatch(deleteDocumentRequest(payload));
    }
  };

  // const getPayloadValues = (data: ICourseReflectionsForm) => {
  //   const formFillingDate = convertToDateTimeWithFormat(
  //     data.formFillingDate,
  //     DATE_FORMAT
  //   );

  //   return {
  //     educatorId: user?.educatorId,
  //     courseOfferingID: location.state.scoId,
  //     formFillingDate: convertToDateTimeUsingOldFormat(
  //       formFillingDate,
  //       DATE_FORMAT,
  //       `${DATE_FORMAT} ${TIME_FORMAT}`
  //     ),
  //     whatWentWell: data.whatWentWell,
  //     howCanWeContinueToDoThis: data.howCanWeContinueToDoThis,
  //     whatDidNotGoSoWell: data.whatDidNotGoSoWell,
  //     whatIdeasDoYouHaveToImproveThis: data.whatIdeasDoYouHaveToImproveThis,
  //   };
  // };

  // const onAddCourseReflectionFormSuccess = (
  //   response: AddEditCourseReflectionFormSuccessPayload
  // ) => {
  //   if (response.success) {
  //     toast.success(REFLECTION_FORM_ADD_SUCCESS_MSG);
  //     getCourseOfferingDetails();
  //   }
  // };

  // const onAddCourseReflectionForm = (payloadValue: ICourseReflectionsForm) => {
  //   const { addCourseReflectionFormRequest } = props;

  //   if (addCourseReflectionFormRequest) {
  //     showLoader();

  //     const payload = {
  //       values: getPayloadValues(payloadValue),
  //       callback: onAddCourseReflectionFormSuccess,
  //     };

  //     addCourseReflectionFormRequest(payload);
  //   }
  // };

  // const onAddCourseReflectionForm = (payloadValue: ICourseReflectionsForm) => {
  //   const { addCourseReflectionFormRequest } = props;

  //   if (addCourseReflectionFormRequest) {
  //     showLoader();

  //     const payload = {
  //       values: getPayloadValues(payloadValue),
  //       callback: onAddCourseReflectionFormSuccess,
  //     };

  //     addCourseReflectionFormRequest(payload);
  //   }
  // };

  // const onEditCourseReflectionFormSuccess = (
  //   response: AddEditCourseReflectionFormSuccessPayload
  // ) => {
  //   if (response.success) {
  //     toast.success(REFLECTION_FORM_EDIT_SUCCESS_MSG);
  //   }
  // };

  // const onEditCourseReflectionForm = (payloadValue: ICourseReflectionsForm) => {
  //   const { editCourseReflectionFormRequest } = props;

  //   if (editCourseReflectionFormRequest) {
  //     showLoader();

  //     const editPayload = getPayloadValues(payloadValue);
  //     const payload = {
  //       values: {
  //         id: payloadValue.id,
  //         ...editPayload,
  //       },
  //       callback: onEditCourseReflectionFormSuccess,
  //     };

  //     editCourseReflectionFormRequest(payload);
  //   }
  // };

  return (
    <div>
      <>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Educator Home Details
          </Typography>
          <Button
            variant="outlined"
            className="btn-back"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
              <Grid item xs={12} lg={6}>
                <SCODetailsSection scoDetails={scheduledOfferingDetails} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12}>
                    <TakeAttendanceSection
                      sessionsList={sessionsList}
                      handleAttendance={handleAttendance}
                      scoDetails={scheduledOfferingDetails}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DocumentsSection
                      documentsList={documentsList}
                      onDownloadDocument={onDownloadDocument}
                      courseOfferingId={scheduledOfferingDetails?.id}
                      onUploadDocument={onUploadDocument}
                      handleDeleteDocModal={handleDeleteDocModal}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <EducatorsSection educatorsList={educatorsList} />
              {/* <CourseReflectionsForm
                scoDetails={scheduledOfferingDetails}
                onAddEditCourseReflectionForm={
                  scheduledOfferingDetails?.educatorCourseReflections
                    ? onEditCourseReflectionForm
                    : onAddCourseReflectionForm
                }
              /> */}
              <EducatorHomeEnrollments
                enroledStudentListForEducatorHomeRequest={
                  enroledStudentListForEducatorHomeRequest
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </>
      <Modal open={attendance} onClose={handleAttendanceClose}>
        <Box className="common-modal attendance-modal">
          <Box className="modal-header">
            <Typography variant="h4">Enter Attendance</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleAttendanceClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ maxWidth: "646px", minWidth: "200px" }}
                    >
                      Student
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "128px", minWidth: "128px" }}
                    >
                      Fill Attendance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceRows.map((row, index) => {
                    return (
                      <TableRow key={row.studentCourseEnrolmentId}>
                        <TableCell component="th" scope="row">
                          {row.studentName}
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableFocusRipple
                                disableRipple
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedBoxIcon />}
                                checked={
                                  attendanceStatus[index]?.isPresent || false
                                }
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label={undefined}
                            labelPlacement="end"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {(!attendanceRows || attendanceRows.length === 0) && <NoRecords />}
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleSaveAttendance}
              disabled={!attendanceRows || attendanceRows.length === 0}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleAttendanceClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenDeleteDocModal}
        handleDeleteConfirmationModalClose={handleDeleteDocCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete document?"
        handleYesClick={onDeleteDocument}
      />
    </div>
  );
};

export default EducatorsHomeDetails;
