import {
  certificateSettings,
  headerLogo,
  lockIcon,
  logoutIcon,
  MenuIcon,
  profileIcon,
} from "assets/images";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import authService from "services/auth-service";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppRoutings } from "utils/enums/app-routings";
import ChangePasswordModal from "pages/ChangePassword";
import ManagerSettings from "pages/ManagerSettings";
import { getPagePermissions } from "utils/helper";
import { AppPages } from "utils/enums/app-pages";
import UserProfileSection from "./UserProfileSection";

const Header: React.FC = () => {
  const history = useHistory();
  const [profileMenu, setProfileMenu] = React.useState<null | HTMLElement>(
    null
  );
  const profileOpen = Boolean(profileMenu);
  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenu(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfileMenu(null);
  };
  const [isStudentLogin, setIsStudentLogin] = React.useState<boolean>(false);
  const [isCertificatePermission, setIsCertificatePermission] =
    React.useState<boolean>(false);
  const [isOpenChangePassword, setIsOpenChangePassword] =
    React.useState<boolean>(false);
  const [isOpenChangeManagerSetting, setIsOpenChangeManagerSetting] =
    React.useState<boolean>(false);
  const handleChangePassword = () => setIsOpenChangePassword(true);
  const handleChangePasswordClose = () => setIsOpenChangePassword(false);

  const user = useSelector((state: any) => state?.auth?.user);
  const logOutClick = () => {
    authService.signOut();
    const isStudent = user?.roleName?.some((role: any) =>
      role?.includes("Student")
    );
    const route = isStudent
      ? { pathname: AppRoutings.LogIn, state: { isEnrolmentLoaded: true } }
      : AppRoutings.LogIn;
    history.push(route);
  };

  useEffect(() => {
    if (user?.roleName?.some((role: string) => role?.includes("Student"))) {
      setIsStudentLogin(true);
    }
    if (
      getPagePermissions(user?.permissions, AppPages.CertificateSettings)?.edit
    ) {
      setIsCertificatePermission(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleName, isStudentLogin, isCertificatePermission]);

  const handleProfilePage = () => {
    if (user?.roleName?.length === 1 && isStudentLogin) {
      history.push({
        pathname: AppRoutings.StudentProfile,
        state: { studentId: user?.studentId },
      });
    } else if (user?.educatorId) {
      history.push({
        pathname: AppRoutings.EducatorProfile,
        state: {
          educatorId: user?.educatorId,
          isEducatorProfile: true,
        },
      });
    } else history.push(AppRoutings.Profile);
  };

  const handleStudentProfileChange = () => {
    history.push({
      pathname: AppRoutings.StudentProfile,
      state: { studentId: user?.studentId },
    });
  };

  return (
    <AppBar position="static" className="header">
      <Toolbar
        disableGutters
        sx={{ flexWrap: "wrap" }}
        className="header-container"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="large"
            onClick={() => document.body.classList.toggle("sidebar-toggle")}
            className="btn-menu"
          >
            <img src={MenuIcon} alt="menu" />
          </IconButton>
          <a
            href={AppRoutings.Dashboard}
            className="header-logo"
            title="South Eastern Sydney Recovery and Wellbeing College"
          >
            <img
              src={headerLogo}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="desktop"
            />
            <img
              src={headerLogo}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="mobile"
            />
          </a>
        </Box>
        <UserProfileSection
          profileOpen={profileOpen}
          handleProfileClick={handleProfileClick}
        />
        <Menu
          id="profile-menu"
          anchorEl={profileMenu}
          open={profileOpen}
          onClose={handleProfileClose}
          MenuListProps={{
            "aria-labelledby": "profile-button",
          }}
          className="profile-menu"
        >
          <MenuItem
            onClick={handleProfileClose}
            sx={{ display: { xs: "flex", sm: "none" } }}
            className="profile-info"
          >
            <Typography variant="h5">
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="h6">{user?.roleName?.toString()}</Typography>
          </MenuItem>
          <MenuItem onClick={handleProfileClose} title="Profile">
            <Button onClick={handleProfilePage}>
              <img src={profileIcon} alt="Profile" />
              <span>Profile</span>
            </Button>
          </MenuItem>
          {user?.roleName?.length > 1 && isStudentLogin && (
            <MenuItem onClick={handleProfileClose} title="Profile">
              <Button onClick={handleStudentProfileChange}>
                <img src={profileIcon} alt="Profile" />
                <span>Student Profile</span>
              </Button>
            </MenuItem>
          )}
          <MenuItem onClick={handleProfileClose} title="ChangePassword">
            <Button onClick={handleChangePassword}>
              <img src={lockIcon} alt="Change password" />
              <span>Change Password</span>
            </Button>
          </MenuItem>
          {isCertificatePermission && (
            <MenuItem onClick={handleProfileClose} title="Certificate Settings">
              <Button onClick={() => setIsOpenChangeManagerSetting(true)}>
                <img src={certificateSettings} alt="Certificate Settings" />
                <span>Certificate Settings</span>
              </Button>
            </MenuItem>
          )}
          <MenuItem onClick={handleProfileClose} title="Logout">
            <Button onClick={logOutClick}>
              <img src={logoutIcon} alt="Logout" />
              <span>Logout</span>
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>

      <ChangePasswordModal
        isOpenChangePasswordModal={isOpenChangePassword}
        handleChangePasswordModalClose={handleChangePasswordClose}
      />
      <ManagerSettings
        isOpenSettingsModal={isOpenChangeManagerSetting}
        handleSettingClose={() => setIsOpenChangeManagerSetting(false)}
      />
    </AppBar>
  );
};

export default Header;
