import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import { ICurrentCourseFilter } from "utils/interfaces/educator-home";
import { TermListSuccessPayload } from "store/term/types";
import { getPagePayloadValues, showLoader } from "utils/helper";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { useDispatch } from "react-redux";
import { termListRequest } from "store/term/actions";
import { OrderByOptions } from "utils/enums/sorting";
import { WHITE_SPACE_ERROR_SPACE } from "utils/constants/Messages";

interface IPastCoursesFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: ICurrentCourseFilter;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getCurrentCoursesList: any;
}

const PastCoursesFilter: React.FC<IPastCoursesFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getCurrentCoursesList,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICurrentCourseFilter>({
    defaultValues: {
      termId: 0,
    },
  });

  const [termList, setTermList] = useState<ITerm[]>();
  const dispatch = useDispatch();

  const getCourseError = (): string => {
    if (errors.course) {
      if (errors.course.type === "pattern") {
        return `Course ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  useEffect(() => {
    if (filteredData) {
      setValue("course", filteredData?.course);
      setValue("termId", filteredData?.termId);
    } else {
      reset();
    }
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    reset();
    setFilterBox(false);
    document.body.classList.toggle("filter-open");
    if (pageNo === DEFAULT_PAGE_NO) {
      getCurrentCoursesList();
    } else {
      setPageNo(1);
    }
    setFilteredData();
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
  };

  const getTermList = () => {
    showLoader();
    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";
    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  useEffect(() => {
    if (filterBox) {
      getTermList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBox]);

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            {/* <TextField
              id="search"
              variant="outlined"
              className="search-input"
              placeholder="Search"
              fullWidth
            /> */}
            <TextField
              id="course"
              label="Course "
              fullWidth
              error={!!errors.course}
              helperText={getCourseError()}
              variant="outlined"
              {...register("course", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
            <FormControl fullWidth className="select">
              <InputLabel id="term-select-label" shrink={watch("termId") !== 0}>
                Term
              </InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                label="Term "
                value={watch("termId")}
                {...register("termId")}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                {termList?.map((term) => (
                  <MenuItem key={term.id} value={term.id}>
                    {term.termName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              disabled={!(watch("termId") || watch("course"))}
              onClick={onClearClick}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default PastCoursesFilter;
