import { Box } from "@mui/system";
import { Button, Fade, IconButton, Modal, Tooltip } from "@mui/material";
import { closeIcon } from "assets/images";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import Config from "config/index";
// import { showLoader } from "utils/helper";
// import { CheckValidReCaptchaSuccessPayload } from "store/studentEnrolment/types";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IReCaptchaModalProps {
  isOpenReCaptchaModal: boolean;
  handleReCaptchaModalClose: any;
  handleYesClick: any;
  props: any;
}

const ReCaptchaModal: React.FC<IReCaptchaModalProps> = ({
  isOpenReCaptchaModal,
  handleReCaptchaModalClose,
  handleYesClick,
  // props,
}) => {
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(true);

  const handleCloseModal = () => {
    handleReCaptchaModalClose();
    setIsDisableSubmit(true);
  };

  const handleReCaptchaSubmit = () => {
    handleCloseModal();
    handleYesClick();
  };

  // const onCheckValidReCaptchaSuccess = (
  //   response: CheckValidReCaptchaSuccessPayload
  // ) => {
  //   if (response.success) {
  //     setIsDisableSubmit(false);
  //   }
  // };

  const handleReCaptchaChange = (value: string | null) => {
    // if (Config.env.NodeEnv === NODE_ENV_TYPES.Development) {
    if (value) setIsDisableSubmit(false);
    // } else {
    //   const { checkValidReCaptchaRequest } = props;
    //   if (checkValidReCaptchaRequest && value) {
    //     showLoader();
    //     const payload = {
    //       values: {
    //         reCaptchaToken: value,
    //       },
    //       callback: onCheckValidReCaptchaSuccess,
    //     };
    //     checkValidReCaptchaRequest(payload);
    //   }
    // }
  };

  return (
    <>
      <Modal open={isOpenReCaptchaModal} onClose={handleCloseModal}>
        <Box className="common-modal delete-modal">
          <Box className="modal-header confirm-modal">
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleCloseModal}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>

          <Box className="modal-body">
            <Box className="confirm-modal-body">
              <ReCAPTCHA
                onChange={handleReCaptchaChange}
                sitekey={Config.env.SiteKey}
              />
            </Box>
          </Box>
          <Box className="recaptcha-submit-footer">
            <Button
              variant="contained"
              onClick={handleReCaptchaSubmit}
              disabled={isDisableSubmit}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default ReCaptchaModal;
