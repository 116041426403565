import {
  chevronDownIcon,
  courseGuideIcon,
  coursesIcon,
  dashboardIcon,
  documentSidebarIcon,
  educatorhomeIcon,
  educatorsIcon,
  handbookIcon,
  locationIcon,
  offeringIcon,
  reportsIcon,
  rolesIcon,
  studentDashboardIcon,
  studentEnrolIcon,
  studentIcon,
  manualIcon,
  termTimetableIcon,
  termsIcon,
  userSidebarIcon,
} from "assets/images";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthState } from "store/rootReducer";
import { getUserSelector } from "store/auth/selector";
import { connect } from "react-redux";
import { ILoginResponse } from "utils/interfaces/login";
import { getPagePermissions } from "utils/helper";
import { AppRoutings } from "utils/enums/app-routings";
import { AppPages } from "utils/enums/app-pages";
import { AdministratorAppPages } from "utils/constants/routes";

export type SidebarProps = {
  user: ILoginResponse;
};

const Sidebar = ({ user }: SidebarProps) => {
  const [openSupport, setOpenSupport] = useState<boolean>(false);

  const handleSupportClick = (isReport?: boolean) => {
    if (openSupport || isReport) setOpenSupport(!openSupport);
  };
  const isHidden = (page: string, parentModule?: string) => {
    const pageAccess = getPagePermissions(
      user?.permissions,
      page,
      parentModule
    );
    if (!pageAccess?.view) {
      return "hidden-list-item";
    }

    return "";
  };

  const isAdministratorHidden = () => {
    const hasViewAccess = AdministratorAppPages.some((page) => {
      const pageAccess = getPagePermissions(
        user?.permissions,
        page,
        "administrator"
      );

      return pageAccess?.view;
    });

    if (hasViewAccess) {
      return "";
    }

    return "hidden-list-item";
  };

  return (
    <Box component="nav" className="sidebar">
      <Typography
        variant="h6"
        className={`sidebar-item ${isAdministratorHidden()}`}
      >
        Administrators
      </Typography>
      <List className="sidebar-menu">
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Dashboard, "administrator")}
        >
          <NavLink to={AppRoutings.Dashboard}>
            <img src={dashboardIcon} alt="Dashboard" />
            Dashboard
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={`${isHidden(AppPages.ScheduledOfferings)}`}
        >
          <NavLink to={AppRoutings.ScheduledOfferings}>
            <img src={offeringIcon} alt="Scheduled Offerings" />
            Scheduled Offerings
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Student)}
        >
          <NavLink to={AppRoutings.Students}>
            <img src={studentIcon} alt="Students" />
            Students
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.StudentEnrolment)}
        >
          <NavLink to={AppRoutings.StudentEnrolments}>
            <img src={studentEnrolIcon} alt="Student Enrolments" />
            Student Enrolments
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Courses)}
        >
          <NavLink to={AppRoutings.Courses}>
            <img src={coursesIcon} alt="Courses" />
            Courses
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Educators)}
        >
          <NavLink to={AppRoutings.Educators}>
            <img src={educatorsIcon} alt="Educators" />
            Educators
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Roles)}
        >
          <NavLink to={AppRoutings.Roles}>
            <img src={rolesIcon} alt="Roles" />
            Roles
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Terms)}
        >
          <NavLink to={AppRoutings.Terms}>
            <img src={termsIcon} alt="Terms" />
            Terms
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Locations)}
        >
          <NavLink to={AppRoutings.Locations}>
            <img src={locationIcon} alt="Locations" />
            Locations
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Users)}
        >
          <NavLink to={AppRoutings.Users}>
            <img src={userSidebarIcon} alt="Users" />
            Users
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Documents)}
        >
          <NavLink to={AppRoutings.Documents}>
            <img src={documentSidebarIcon} alt="Documents" />
            Documents
          </NavLink>
        </ListItemButton>
        <div className={openSupport ? "expanded container" : "container"}>
          <ListItemButton
            onClick={() => handleSupportClick(true)}
            className={isHidden(AppPages.Reports)}
          >
            <span className="nav-item">
              <img src={reportsIcon} alt="Reports" />
              Reports
              <img src={chevronDownIcon} alt="Arrow" className="arrow" />
            </span>
          </ListItemButton>
          {/* ------------- Submenu --------------- */}
          <Collapse in={openSupport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.StudentReport}
              >
                <ListItemText
                  primary="Enrolments"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.StaffReport}
              >
                <ListItemText
                  primary="Staff"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.CourseReport}
              >
                <ListItemText
                  primary="Courses"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.SLPReport}
              >
                <ListItemText
                  primary="SLP"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </List>
          </Collapse>
        </div>
      </List>
      <Typography
        variant="h6"
        className={`sidebar-item ${isHidden(AppPages.EducatorHome)}`}
      >
        Educator
      </Typography>
      <List className="sidebar-menu">
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.EducatorHome)}
        >
          <NavLink to={AppRoutings.EducatorsHome}>
            <img src={educatorhomeIcon} alt="Educators Home" />
            Educator Home
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.EducatorHome)}
        >
          <NavLink to={AppRoutings.EducatorHandbook}>
            <img src={handbookIcon} alt="Educator Handbook" />
            Educator Handbook
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.EducatorHome)}
        >
          <NavLink to={AppRoutings.EducatorDocuments}>
            <img src={documentSidebarIcon} alt="Documents" />
            Useful Documents and Templates
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.EducatorHome)}
        >
          <NavLink to={AppRoutings.EducatorManual}>
            <img src={manualIcon} alt="Manual" />
            System User Manual
          </NavLink>
        </ListItemButton>
      </List>
      <Typography
        variant="h6"
        className={`sidebar-item ${isHidden(
          AppPages.StudentDashboard,
          "student"
        )}`}
      >
        Student
      </Typography>
      <List className="sidebar-menu">
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.StudentDashboard, "student")}
        >
          <NavLink to={AppRoutings.StudentDashboard}>
            <img src={studentDashboardIcon} alt="Students Dashboard" />
            Dashboard
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.MyEnrolment, "student")}
        >
          <NavLink to={AppRoutings.MyEnrolment}>
            <img src={studentEnrolIcon} alt="My Enrolments" />
            My Enrolments
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Dashboard, "student")}
        >
          <NavLink to={AppRoutings.Handbook}>
            <img src={handbookIcon} alt="Handbook" />
            Handbook
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Dashboard, "student")}
        >
          <NavLink to={AppRoutings.CourseGuide}>
            <img src={courseGuideIcon} alt="Course Guide" />
            Course Guide
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Dashboard, "student")}
        >
          <NavLink to={AppRoutings.TermTimetable}>
            <img src={termTimetableIcon} alt="Term Timetable" />
            Term Timetable
          </NavLink>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSupportClick(false)}
          className={isHidden(AppPages.Dashboard, "student")}
        >
          <NavLink to={AppRoutings.StudentManual}>
            <img src={manualIcon} alt="Manual" />
            Manual
          </NavLink>
        </ListItemButton>
      </List>
    </Box>
  );
};

const mapStateToProps = (state: AuthState) => {
  return {
    user: getUserSelector(state),
  };
};

export default connect(mapStateToProps)(Sidebar);
