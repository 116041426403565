export enum AppRoutings {
  Dashboard = "/dashboard",
  LogIn = "/login",
  Error = "/error",
  ResetPassword = "/reset-password/:token",
  ForgotPassword = "/forgot-password",
  Roles = "/roles",
  AddRole = "/roles/add",
  EditRole = "/roles/edit",
  Locations = "/locations",
  Terms = "/terms",
  Profile = "/profile",
  Courses = "/courses",
  AddCourse = "/courses/add",
  EditCourse = "/courses/edit",
  Educators = "/educators",
  AddEducator = "/educators/add",
  EditEducator = "/educators/edit",
  ScheduledOfferings = "/scheduled-offerings",
  ScheduledOfferingsDetails = "/scheduled-offerings/details",
  CoursesDelivered = "/educators/courses-delivered",
  CoursesAttendedAsStudent = "/educators/courses-attended-as-student",
  EducatorsHome = "/educators-home",
  EducatorsHomeDetails = "/educators-home/details",
  StudentEnrolmentForm = "/student-enrolment",
  StudentEnrolments = "/student-enrolments",
  Students = "/students",
  StudentsDetails = "/students/details",
  Users = "/users",
  EnrollmentApproval = "/student-enrolments/enrolment-approval",
  EnrollmentSummary = "/students/enrollmentSummary",
  Documents = "/documents",
  StudentAttendance = "/students/studentAttendance",
  StudentDashboard = "/student/dashboard",
  MyEnrolment = "/student/my-enrolment",
  StudentProfile = "/student-profile",
  StudentDashboardCourseDetails = "/student/dashboard/course-details",
  MyEnrolmentCourseDetails = "/student/my-enrolment/course-details",
  Handbook = "/student/handbook",
  EducatorHandbook = "/educator/educator-handbook",
  CourseGuide = "/student/course-guide",
  TermTimetable = "/student/term-timetable",
  StudentReport = "/report-enrolment",
  StaffReport = "/report-staff",
  CourseReport = "/report-course",
  SLPReport = "/report-slp",
  EducatorDocuments = "/educator-documents",
  StudentManual = "/student/manual",
  EducatorManual = "/educator/manual",
  EducatorProfile = "/educator/profile",

  // Designer Routes
  DesignerLogin = "/designer-login",
  DesignerStudentLogin = "/designer-student-login",
  DesignerDashboard = "/designer-dashboard",
  DesignerRoles = "/designer-roles",
  DesignerForgotPassword = "/designer-forgot-password",
  DesignerResetPassword = "/designer-reset-password",
  DesignerLocation = "/designer-location",
  DesignerTerms = "/designer-terms",
  DesignerEducators = "/designer-educators",
  DesignerCourses = "/designer-courses",
  DesignerEducatorsHome = "/designer-educators-home",
  DesignerProfile = "/designer-profile",
  DesignerSco = "/designer-schedule-course-offering",
  DesignerStudentTemp = "/designer-student-template",
  DesignerStudent = "/designer-student",
  DesignerStudentEnrol = "/designer-student-enrolment",
  DesignerStudentDashboard = "/designer-student-dashboard",
  DesignerReportsStudent = "/designer-report-student",
  DesignerReportStaff = "/designer-report-staff",
  DesignerReportEducator = "/designer-report-educator",
}
