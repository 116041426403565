/* eslint-disable no-plusplus */
import { JWTToken } from "utils/interfaces/JWT-token";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import authService from "services/auth-service";
import { History } from "history";
import { LoginSuccessPayload } from "store/auth/types";
import { ILoginResponse } from "utils/interfaces/login";
import { IRole } from "utils/interfaces/role";
import moment from "moment";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import {
  DOCX_FILE_TYPE,
  DOC_FILE_TYPE,
  INVALID_CHAR_FOR_NUMBER_TYPE,
  MAX_INT_VALUE,
  PDF_FILE_TYPE,
  SCO_DOC_SUPPORTED_TYPES,
  TOKEN_EXPIRES_IN_MINUTES,
  TOKEN_EXPIRES_IN_DAYS,
  ALPHABETIC_INPUT_REGEX,
} from "utils/constants/constant";
import { TermStatus } from "utils/enums/term";
import { AppPages } from "utils/enums/app-pages";
import { AppRoutings } from "utils/enums/app-routings";
import { OrderBy, OrderByOptions } from "utils/enums/sorting";
import { publicRoutes } from "./public-routes";

export const EmailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PasswordPattern =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>[~'"`?/\]]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/;

export const NoWhiteSpace = /\S/;

export const LandlineRegex = /^(?:\+?61|\(?0)[2378]\)?(?:[ -]?[0-9]){8}$/;

export const setCookie = (name: string, value: string) => {
  const twoHrsExpireTime = new Date(
    new Date().getTime() + TOKEN_EXPIRES_IN_MINUTES * 60 * 1000
  );
  Cookies.set(name, value, { expires: twoHrsExpireTime });
};

export const setCookieWithExpiry = (name: string, value: string) => {
  Cookies.set(name, value, { expires: TOKEN_EXPIRES_IN_DAYS });
};

export const getCookie = (name: string) => {
  const cookie = Cookies.get(name);
  if (cookie) {
    return cookie;
  }

  return "";
};

export const clearCookie = (name: string) => {
  Cookies.remove(name);
};

export const getDecodedToken = (token: string): JWTToken => {
  try {
    const decodedValue = jwtDecode(token);

    return new JWTToken(decodedValue as JWTToken);
  } catch {
    return new JWTToken();
  }
};

const convertToAEST = (date: Date): number => {
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: "Australia/Sydney",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  return Date.parse(formatter.format(date)) / 1000;
};

export const isTokenExpired = (token: string): boolean => {
  const expireTime = getDecodedToken(token).exp;
  const currentAESTTime = convertToAEST(new Date());
  if (expireTime) {
    return currentAESTTime > expireTime;
  }

  return false;
};

export const isRefreshTokenRequired = (
  token: string,
  user: ILoginResponse
): boolean => {
  const expireTime = getDecodedToken(token).exp;

  const currentTime = new Date().getTime() / 1000;
  const calculatedTime =
    expireTime -
    user?.tokenExpiratimeForRememberMe * 24 * 3600 +
    user?.timeDurationForRefreshToken * 3600;
  if (expireTime) {
    return currentTime > calculatedTime;
  }

  return false;
};

export const checkValidToken = async (
  history: History<unknown>,
  userValues: ILoginResponse,
  token: string,
  refreshTokenCall: any
) => {
  if (token) {
    const rememberCookie = getCookie("remember_me");
    if (
      isTokenExpired(token) &&
      !window.location.pathname.split("/")[1].includes("student-enrolment") &&
      !window.location.pathname.split("/")[1].includes("forgot-password")
    ) {
      authService.signOut();
      history.push(AppRoutings.LogIn);
    } else {
      if (
        publicRoutes.includes(
          window.location.pathname.toLowerCase().split("/")[1]
        ) &&
        !window.location.pathname.split("/")[1].includes("designer") &&
        !window.location.pathname.split("/")[1].includes("student-enrolment") &&
        (!window.location.pathname.split("/")[1].includes("forgot-password") ||
          rememberCookie)
      ) {
        history.push(AppRoutings.Dashboard);
      }
      let rememberMe = false;

      if (!rememberCookie) {
        authService.signOut();
        if (
          window.location.pathname.split("/")[1].includes("student-enrolment")
        ) {
          history.push(AppRoutings.StudentEnrolmentForm);
        } else if (
          window.location.pathname.split("/")[1].includes("forgot-password")
        ) {
          history.push(AppRoutings.ForgotPassword);
        } else {
          history.push(AppRoutings.LogIn);
        }
      }
      if (rememberCookie) {
        rememberMe = JSON.parse(rememberCookie);
      }

      if (rememberMe) {
        const needRefreshToken: boolean = isRefreshTokenRequired(
          token,
          userValues
        );

        if (needRefreshToken) {
          refreshTokenCall();
        }
      }
    }
  } else if (publicRoutes.includes(window.location.pathname.split("/")[1])) {
    authService.signOut();
  } else {
    authService.signOut();
    history.push(AppRoutings.LogIn);
  }
};

export const setLoginDetails = async (loginDetails: LoginSuccessPayload) => {
  if (loginDetails.user?.rememberMe) {
    setCookieWithExpiry("auth_token", loginDetails.token);
    setCookieWithExpiry(
      "remember_me",
      JSON.stringify(loginDetails.user?.rememberMe)
    );
  } else {
    setCookie("auth_token", loginDetails.token);
    setCookie("remember_me", JSON.stringify(loginDetails.user?.rememberMe));
  }
};

export const showLoader = (): void => {
  const loaderDiv = document.getElementById("loaderForAPICall");
  const loaderDivForBatchApiCall = document.getElementById(
    "loaderForBatchAPICall"
  );

  if (loaderDivForBatchApiCall?.classList.contains("loaderShow")) {
    return;
  }
  if (loaderDiv) {
    loaderDiv.classList.add("loaderShow");
    loaderDiv.classList.remove("loaderHide");
  }
};

export const hideLoader = (): void => {
  const loaderDiv = document.getElementById("loaderForAPICall");
  if (loaderDiv) {
    loaderDiv.classList.remove("loaderShow");
    loaderDiv.classList.add("loaderHide");
  }
};

export const showLoaderForBatchApiCall = (): void => {
  const loaderDiv = document.getElementById("loaderForBatchAPICall");
  if (loaderDiv) {
    loaderDiv.classList.add("loaderShow");
    loaderDiv.classList.remove("loaderHide");
  }
};

export const hideLoaderForBatchApiCall = (): void => {
  const loaderDiv = document.getElementById("loaderForBatchAPICall");
  if (loaderDiv) {
    loaderDiv.classList.remove("loaderShow");
    loaderDiv.classList.add("loaderHide");
  }
};

export const getPagePermissions = (
  allPermissions: any,
  page: string,
  parentModule?: string
) => {
  const modules = allPermissions?.moduleResponses;
  const pagePermission: any = [];
  modules?.forEach((module: any) => {
    if ((parentModule !== undefined || "") && page === AppPages.Dashboard) {
      const permissions = module?.rolePermissionsResponses;
      if (
        module?.rolePermissionsResponses !== null &&
        module?.moduleName?.toLowerCase() === parentModule
      ) {
        permissions?.forEach((permission: any) => {
          if (permission?.page?.toLowerCase() === page.toLowerCase()) {
            pagePermission.push(permission);
          }
        });
      }
    } else {
      const permissions = module?.rolePermissionsResponses;
      if (module?.rolePermissionsResponses !== null) {
        permissions?.forEach((permission: any) => {
          if (permission?.page?.toLowerCase() === page.toLowerCase()) {
            pagePermission.push(permission);
          }
        });
      }
    }
  });

  const finalPermission = {
    add: false,
    delete: false,
    edit: false,
    page: pagePermission?.[0]?.page,
    view: false,
    rolePermissionID: pagePermission?.[0]?.rolePermissionID,
  };
  const viewPr = pagePermission.find((item: any) => item.view);
  const addPer = pagePermission.find((item: any) => item.add);
  const editPer = pagePermission.find((item: any) => item.edit);
  const deletePer = pagePermission.find((item: any) => item.delete);

  if (viewPr) {
    finalPermission.view = viewPr.view;
  }
  if (addPer) {
    finalPermission.add = addPer.add;
  }
  if (editPer) {
    finalPermission.edit = editPer.edit;
  }
  if (deletePer) {
    finalPermission.delete = viewPr.delete;
  }

  return finalPermission;
};

export const getTermStatusNumber = (startDate: Date, endDate: Date) => {
  const today = new Date();
  if (today >= startDate && today <= endDate) {
    return TermStatus.CurrentTerm;
  }
  if (today < startDate) {
    return TermStatus.FutureTerm;
  }

  return TermStatus.PastTerm;
};

export const checkEndDateLessThanStart = (start: Date, end: Date) => {
  if (start && end) {
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    const startDate = moment(
      moment(dateStart).format("MM-DD-YYYY"),
      "MM-DD-YYYY"
    );
    const endDate = moment(moment(dateEnd).format("MM-DD-YYYY"), "MM-DD-YYYY");

    if (endDate.diff(startDate, "days") < 0) {
      return true;
    }

    return false;
  }

  return false;
};

export const checkEndDateLessThanOrEqualToStart = (start: Date, end: Date) => {
  if (start && end) {
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    const startDate = moment(
      moment(dateStart).format("MM-DD-YYYY"),
      "MM-DD-YYYY"
    );
    const endDate = moment(moment(dateEnd).format("MM-DD-YYYY"), "MM-DD-YYYY");

    if (endDate.diff(startDate, "days") <= 0) {
      return true;
    }

    return false;
  }

  return false;
};

export const checkEndDateLessThanToStart = (start: Date, end: Date) => {
  if (start && end) {
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    const startDate = moment(
      moment(dateStart).format("MM-DD-YYYY"),
      "MM-DD-YYYY"
    );
    const endDate = moment(moment(dateEnd).format("MM-DD-YYYY"), "MM-DD-YYYY");

    if (endDate.diff(startDate, "days") < 0) {
      return true;
    }

    return false;
  }

  return false;
};

export const yearRange = () => {
  const range = [];

  for (let minRange = 2000; minRange <= 2040; minRange++) {
    const item = { value: minRange, label: minRange };
    range.push(item);
  }

  return range;
};

export const getHTMLFromDraftEditor = (editor: EditorState): string =>
  draftToHtml(convertToRaw(editor.getCurrentContent()));

export const createEditorStateFromHTML = (htmlText: string): EditorState => {
  const contentBlock = htmlToDraft(htmlText);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );

  return EditorState.createWithContent(contentState);
};

export const stringToPascalCase = (str: string) => {
  const string = str
    .split(/(?=[A-Z])/)
    .join(" ")
    .toLowerCase()
    .replace(/(^|\s)\S/g, (match) => match.toUpperCase());

  return string.replaceAll(" ", "");
};

export const roleRenderValue = (selected: Array<number>, roleList: IRole[]) => {
  const selectedString: string[] = [];
  selected.forEach((item: number) => {
    const roleItem = roleList?.find((role) => role.id === item);
    if (roleItem) {
      selectedString.push(roleItem.roleName);
    }
  });

  return selectedString.join(", ");
};

export const onNumberFieldKeyPress = (event: any, intField?: boolean) => {
  if (INVALID_CHAR_FOR_NUMBER_TYPE.includes(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
  if (
    intField &&
    event.target.value?.toString().length >=
      MAX_INT_VALUE?.toString().length - 1
  ) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const handlePasteNumberField = (e: any) => {
  const pasteData = e.clipboardData?.getData("Text");
  if (
    pasteData.includes("e") ||
    pasteData.includes("E") ||
    pasteData.includes("+") ||
    pasteData.includes("-")
  ) {
    e.preventDefault();
  }
};

export const educatorRenderValue = (
  selected: Array<number>,
  educatorList: any
) => {
  const selectedString: string[] = [];
  selected.forEach((item: number) => {
    const educatorItem = educatorList?.find((edu: any) => edu.id === item);
    if (educatorItem) {
      selectedString.push(educatorItem.name);
    }
  });

  return selectedString.join(", ");
};

export const checkEndTimeLessThanStart = (start: any, end: any) => {
  const startTime = moment(new Date(start));
  const endTime = moment(new Date(end));

  if (endTime.isBefore(startTime)) {
    return true;
  }

  return false;
};

export const base64ToArrayBuffer = (base64: any) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

export const allowSearch = (e: any) => {
  return (
    (e?.key === "Enter" && e.target.value?.trim()) ||
    (e?.key === "Enter" && !e.target.value)
  );
};

export const getPaginationDetailText = (
  listMeta: any,
  pageNo: number,
  page: string
) => {
  let rangeText = "";

  if (listMeta) {
    const minRange = (Number(pageNo) - 1) * Number(page) + 1;

    let maxRange = Number(pageNo) * Number(page);
    if (maxRange > Number(listMeta?.totalCount)) {
      maxRange = Number(listMeta?.totalCount);
    }
    const rangeString = `${minRange} - ${maxRange}`;
    rangeText = `Showing ${rangeString} of ${listMeta?.totalCount} entries`;
  }

  return rangeText;
};

export const getPagePayloadValues = (
  orderBy: string,
  order: string,
  pageNo: number,
  page: string | number
) => {
  return {
    sortColumn: stringToPascalCase(orderBy),
    sortBy: order === OrderByOptions.Asc ? OrderBy.Asc : OrderBy.Desc,
    pageNo: Number(pageNo),
    pageSize: Number(page),
  };
};

export const getPagePayloadValuesWithId = (
  id: number,
  orderBy: string,
  order: string,
  pageNo: number,
  page: string | number
) => {
  return {
    id: Number(id),
    sortColumn: stringToPascalCase(orderBy),
    sortBy: order === OrderByOptions.Asc ? OrderBy.Asc : OrderBy.Desc,
    pageNo: Number(pageNo),
    pageSize: Number(page),
  };
};

export const convertToDateTime = (dateTime: any, format?: string) => {
  if (format) {
    return moment(dateTime).format(format);
  }

  return moment(dateTime).format();
};

export const convertToDateTimeWithFormat = (dateTime: any, format: string) => {
  return moment(new Date(dateTime)).format(format);
};

export const convertToDateTimeUsingOldFormat = (
  dateTime: any,
  dateTimeFormat: string,
  format: string
) => {
  return moment(dateTime, dateTimeFormat).format(format);
};

export const getScoDocAcceptedTypes = () =>
  SCO_DOC_SUPPORTED_TYPES.map((type) => type).join(", ");

export const getUploadedScoDocumentType = (docName: string) => {
  const type = docName.split(".").pop();

  switch (type) {
    case "pdf":
      return PDF_FILE_TYPE;
    case "doc":
      return DOC_FILE_TYPE;
    case "docx":
      return DOCX_FILE_TYPE;
    default:
      return PDF_FILE_TYPE;
  }
};

export const onTextFieldKeyPress = (
  event: any,
  wordlength: number | undefined
) => {
  if (wordlength === 0 || wordlength === undefined) {
    if (event.key === " ") {
      event.preventDefault();
      event.stopPropagation();
    }
  }
};

export const toggleFilter = () => {
  document.body.classList.toggle("filter-open");
};

export const onOnlyAlphabeticFieldKeypress = (event: any) => {
  const isAlphabetic = ALPHABETIC_INPUT_REGEX.test(event.key);
  if (!isAlphabetic) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const handlePasteAlphabeticField = (event: any) => {
  const pasteData = event.clipboardData?.getData("Text");
  if (!ALPHABETIC_INPUT_REGEX.test(pasteData)) {
    event.preventDefault();
  }
};

export const DisabledEnrolmentStatuses = ["Rejected", "Withdrawn"];
export const EnabledEnrolmentStatuses = ["Pending", "Approved", "WaitListed"];
export const checkEmailEqualSecondaryEmail = (
  email: string,
  secondaryEmail: string
) => {
  if (
    email.toLocaleLowerCase() === secondaryEmail?.toLocaleLowerCase() &&
    secondaryEmail !== ""
  ) {
    return true;
  }

  return false;
};

export const calculateAge = (dateOfBirth: string) => {
  const dob = new Date(dateOfBirth);
  const today = new Date();

  let age = today.getFullYear() - dob.getFullYear();
  const monthDifference = today.getMonth() - dob.getMonth();
  const dayDifference = today.getDate() - dob.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};
